@tailwind base;
@tailwind components;

@layer components {
  [type='radio']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23292929' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  }
  [type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23262626' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
  .form-checkbox-tag:checked {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%227%22%20viewBox%3D%220%200%206%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M4.71778%205.97461C4.91304%206.16987%205.22962%206.16987%205.42488%205.97461C5.62014%205.77935%205.62014%205.46277%205.42488%205.26751L3.65693%203.49955L5.42443%201.73205C5.6197%201.53678%205.6197%201.2202%205.42443%201.02494C5.22917%200.829678%204.91259%200.829678%204.71733%201.02494L2.94982%202.79245L1.18224%201.02486C0.986979%200.829603%200.670397%200.829603%200.475135%201.02486C0.279873%201.22013%200.279873%201.53671%200.475135%201.73197L2.24272%203.49955L0.474688%205.26758C0.279425%205.46284%200.279425%205.77943%200.474688%205.97469C0.66995%206.16995%200.986532%206.16995%201.18179%205.97469L2.94982%204.20666L4.71778%205.97461Z%22%20fill%3D%22%23292929%22%2F%3E%0A%3C%2Fsvg%3E');
  }
  [type='checkbox'],
  [type='radio'] {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

@layer base {
  @font-face {
    font-family: 'Monument Grotesk';
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/monument-grotesk/ABCMonumentGrotesk-Regular.woff2)
        format('woff2'),
      url(/fonts/monument-grotesk/ABCMonumentGrotesk-Regular.woff)
        format('woff');
  }
  @font-face {
    font-family: 'Monument Grotesk';
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(/fonts/monument-grotesk/ABCMonumentGrotesk-RegularItalic.woff2)
        format('woff2'),
      url(/fonts/monument-grotesk/ABCMonumentGrotesk-RegularItalic.woff)
        format('woff');
  }
  @font-face {
    font-family: 'Monument Grotesk';
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/monument-grotesk/ABCMonumentGrotesk-Bold.woff2)
        format('woff2'),
      url(/fonts/monument-grotesk/ABCMonumentGrotesk-Bold.woff) format('woff');
  }

  @font-face {
    font-family: 'Synt';
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/synt/ABCSynt-Regular.woff2) format('woff2'),
      url(/fonts/synt/ABCSynt-Regular.woff) format('woff');
  }
  @font-face {
    font-family: 'Synt';
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(/fonts/synt/ABCSynt-Italic.woff2) format('woff2'),
      url(/fonts/synt/ABCSynt-Italic.woff) format('woff');
  }

  body {
    @apply antialiased text-grey-900;
  }
}

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

/* https://github.com/firebase/firebase-tools/issues/2773#issuecomment-918861851 */
.firebase-emulator-warning {
  opacity: 0.15;
  pointer-events: none;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
@layer utilities {
  .scrollbar-width-thin {
    scrollbar-width: thin;
  }
  .scroll-snap-x-mandatory {
    scroll-snap-type: x mandatory;
  }
  .scroll-snap-align-start {
    scroll-snap-align: start;
  }
}
